import { css } from "@emotion/react";
import variables from "./variables";

const marketingStyles = css`
  @font-face {
    font-family: "EuclidCircularA";
    src: url("/static/fonts/EuclidCircularA-Light-WebS.eot");
    src: url("/static/fonts/EuclidCircularA-Light-WebS.eot?#iefix")
        format("embedded-opentype"),
      url("/static/fonts/EuclidCircularA-Light-WebS.woff") format("woff"),
      url("/static/fonts/EuclidCircularA-Light-WebS.ttf") format("truetype"),
      url("/static/fonts/EuclidCircularA-Light-WebS.svg#EuclidCircularA")
        format("svg");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "EuclidCircularA";
    src: url("/static/fonts/EuclidCircularA-Regular-WebS.eot");
    src: url("/static/fonts/EuclidCircularA-Regular-WebS.eot?#iefix")
        format("embedded-opentype"),
      url("/static/fonts/EuclidCircularA-Regular-WebS.woff") format("woff"),
      url("/static/fonts/EuclidCircularA-Regular-WebS.ttf") format("truetype"),
      url("/static/fonts/EuclidCircularA-Regular-WebS.svg#EuclidCircularA")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "EuclidCircularA";
    src: url("/static/fonts/EuclidCircularA-Semibold-WebS.eot");
    src: url("/static/fonts/EuclidCircularA-Semibold-WebS.eot?#iefix")
        format("embedded-opentype"),
      url("/static/fonts/EuclidCircularA-Semibold-WebS.woff") format("woff"),
      url("/static/fonts/EuclidCircularA-Semibold-WebS.ttf") format("truetype"),
      url("/static/fonts/EuclidCircularA-Semibold-WebS.svg#EuclidCircularA")
        format("svg");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "EuclidCircularA";
    src: url("/static/fonts/EuclidCircularA-Medium-WebS.eot");
    src: url("/static/fonts/EuclidCircularA-Medium-WebS.eot?#iefix")
        format("embedded-opentype"),
      url("/static/fonts/EuclidCircularA-Medium-WebS.woff") format("woff"),
      url("/static/fonts/EuclidCircularA-Medium-WebS.ttf") format("truetype"),
      url("/static/fonts/EuclidCircularA-Medium-WebS.svg#EuclidCircularA")
        format("svg");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "EuclidCircularA";
    src: url("/static/fonts/EuclidCircularA-Bold-WebS.eot");
    src: url("/static/fonts/EuclidCircularA-Bold-WebS.eot?#iefix")
        format("embedded-opentype"),
      url("/static/fonts/EuclidCircularA-Bold-WebS.woff") format("woff"),
      url("/static/fonts/EuclidCircularA-Bold-WebS.ttf") format("truetype"),
      url("/static/fonts/EuclidCircularA-Bold-WebS.svg#EuclidCircularA")
        format("svg");
    font-weight: bold;
    font-style: normal;
  }

  body {
    font-family: ${variables.marketingBaseFontFamily};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${variables.marketingHeaderFontFamily};
  }
`;

export default marketingStyles;
