import React, { useState, useRef, useEffect, ReactElement } from "react";
import { css } from "@emotion/react";
import { observer } from "mobx-react-lite";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useRouter } from "next/router";
import useOnclickOutside from "react-cool-onclickoutside";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import ActiveLink from "../common/active-link";
import HamburgerMenu from "../../public/static/icons/hamburger-menu.svg";
import visuallyHidden from "../../styles/visually-hidden";
import i18n from "../../helpers/i18n";

const smallBreakPoint = pxToRem(817);

const appMobileNav = css`
  @media (min-width: ${smallBreakPoint}) {
    display: none;
  }
`;

const logo = css`
  opacity: 0;
  width: auto;
  height: 1.1rem;
  transition: opacity ${variables.transitionSpeed} ease;
`;

const logoVisible = css`
  opacity: 1;
`;

const navSection = css`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${variables.spaceMedium};
  height: ${pxToRem(variables.navHeightMobile)};
`;

const mobileMenu = css`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, ${pxToRem(variables.navHeight + 20)});
  background: ${variables.colorWhite};
  width: 95%;
  box-shadow: 0 ${pxToRem(5)} ${pxToRem(10)} rgba(0, 0, 0, 0.15);
  border-radius: ${pxToRem(3)};
  border: ${pxToRem(1)} solid ${variables.colorDarkContainerGray};
  padding: ${pxToRem(12)};
`;

const mobileMenuList = css`
  display: grid;
  grid-template-rows: 1fr;
  align-content: center;
  align-items: center;
`;

const mobileLink = css`
  width: 100%;
  display: block;
  padding: ${pxToRem(16)} ${pxToRem(12)};
  color: ${variables.colorText};
  border-radius: ${pxToRem(3)};
  text-align: center;
`;

const activeMobileLink = css`
  background-color: ${variables.colorBackground};
`;

const mobileMenuButton = css`
  width: ${pxToRem(34)};
  height: ${pxToRem(variables.navHeightMobile)};
  text-align: center;
`;

const menuIcon = css`
  vertical-align: middle;
`;

type Input = {
  hasJwtToken: boolean;
  onScheduleDemo?: () => void;
  workspaceName: string;
  workspaceLogoUrl: string;
};

const MarketingMobileNav = observer(
  ({
    hasJwtToken,
    onScheduleDemo,
    workspaceName,
    workspaceLogoUrl,
  }: Input): ReactElement => {
    const rootRef = useRef();
    const router = useRouter();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [shouldCheckScroll, setShouldCheckScroll] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);

    const toggleMainMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
      if (router.pathname === "/") {
        setHasScrolled(false);
        setShouldCheckScroll(true);
      } else {
        setHasScrolled(true);
        setShouldCheckScroll(false);
      }
    }, []);

    useOnclickOutside(
      () => {
        setIsMenuOpen(false);
      },
      { refs: [rootRef] }
    );

    useScrollPosition(
      ({ currPos }) => {
        if (!hasScrolled && shouldCheckScroll && currPos.y < -600) {
          setHasScrolled(true);
        } else if (hasScrolled && shouldCheckScroll && currPos.y >= -600) {
          setHasScrolled(false);
        }
      },
      [hasScrolled, shouldCheckScroll]
    );

    return (
      <div css={appMobileNav} ref={rootRef}>
        {isMenuOpen && (
          <nav css={mobileMenu}>
            <ul css={mobileMenuList}>
              {!hasJwtToken && (
                <li>
                  <ActiveLink
                    style={mobileLink}
                    activeStyle={activeMobileLink}
                    href="/sign-in"
                  >
                    {i18n.t("signIn")}
                  </ActiveLink>
                </li>
              )}
              {hasJwtToken && (
                <li>
                  <ActiveLink style={mobileLink} href="/challenges">
                    Continue
                  </ActiveLink>
                </li>
              )}
              <li>
                <button css={mobileLink} onClick={onScheduleDemo}>
                  Schedule Demo
                </button>
              </li>
              <li>
                <ActiveLink
                  style={mobileLink}
                  activeStyle={activeMobileLink}
                  href="/resources"
                >
                  {i18n.t("resources")}
                </ActiveLink>
              </li>
            </ul>
          </nav>
        )}
        <div css={navSection}>
          <div>
            <img
              css={[logo, hasScrolled && logoVisible]}
              src={workspaceLogoUrl}
              alt={workspaceName}
            />
          </div>
          <button type="button" css={mobileMenuButton} onClick={toggleMainMenu}>
            <HamburgerMenu css={menuIcon} aria-hidden />
            <span css={visuallyHidden}>Open Menu</span>
          </button>
        </div>
      </div>
    );
  }
);

export default MarketingMobileNav;
