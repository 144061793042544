import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { css, Global } from "@emotion/react";
import Head from "next/head";

import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const { websocketHost } = publicRuntimeConfig;

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import ActiveLink from "../common/active-link";
import DisplayTime from "../common/display-time";
import { h5Regular, h6Regular } from "../../styles/typography";
import MarketingMobileNav from "./marketing-mobile-nav";
import { getLocalStorage } from "../../helpers/local-storage";
import ExternalLink from "../common/external-link";
import HeroButton from "../common/hero-button";
import marketingStyles from "../../styles/marketing-styles";
import { DEFAULT_LOGO_URL, WSEvent } from "../../constants";
import fws from "../../helpers/websocket";
import i18n from "../../helpers/i18n";

const smallBreakPoint = pxToRem(817);
const bannerHeight = 56;

// Header

const logoFull = css`
  display: none;
  width: auto;
  height: 1.75rem;

  @media (min-width: ${smallBreakPoint}) {
    display: block;
  }
`;

const navSection = css`
  z-index: 11;
  position: sticky;
  top: 0;
  background-color: ${variables.colorMainBlue};
`;

const desktopNav = css`
  display: none;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  height: ${pxToRem(variables.navHeight)};
  justify-content: space-between;
  padding: 0 ${variables.spaceMedium};

  @media (min-width: ${smallBreakPoint}) {
    display: grid;
  }
`;

const menuList = css`
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  gap: 1rem;
`;

const link = css`
  display: block;
  height: ${pxToRem(32)};
  padding: 0 ${pxToRem(24)};
  border-radius: ${pxToRem(5)};
  color: ${variables.colorWhite};
  font-size: ${pxToRem(12)};
  font-weight: ${variables.fontWeightSemiBold};
  line-height: ${pxToRem(30)};
`;

const featuredLink = css`
  ${link};
  border: ${pxToRem(1)} solid ${variables.colorWhite};
`;

const scheduleDemoButton = css`
  min-height: ${pxToRem(32)};
  padding: ${pxToRem(6)} ${pxToRem(24)};
  font-size: ${pxToRem(14)};

  @media (min-width: ${smallBreakPoint}) {
    padding: ${pxToRem(6)} ${pxToRem(16)};
  }
`;

// Main Footer Wrapper
const mainFooterWrapper = css`
  /** padding-top: ${pxToRem(
    bannerHeight
  )}; Enable when used with HeaderBanner **/
  display: grid;
  min-height: 100vh;
  grid-template-rows: 1fr;
`;

const navAndFooterWrapper = css`
  grid-template-rows: auto 1fr auto;
`;

// Main Content

const content = css`
  display: grid;
  width: 100%;

  @media (min-width: ${smallBreakPoint}) {
    padding-bottom: 0;
  }
`;

// Footer

const footer = css`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-gap: ${variables.spaceSmall};
  background: ${variables.colorBackground};
  padding: ${variables.spaceBase} ${variables.spaceXLarge};

  @media (min-width: ${variables.tabletMinWidth}) {
    justify-content: space-between;
    grid-template-columns: auto auto;
  }
`;

const freshLogo = css`
  width: auto;
  height: ${pxToRem(24)};
`;

const poweredBy = css`
  display: grid;
  align-items: center;
  justify-content: start;
  grid-gap: ${variables.spaceSmall};
  grid-template-columns: auto auto;
`;

const poweredByLabel = css`
  ${h5Regular};
  letter-spacing: ${pxToRem(1)};
  color: ${variables.colorSecondaryGray};
`;

const footerCopyrightLink = css`
  display: grid;
  grid-template-areas: "links" "copyright";
  grid-auto-flow: row;
  justify-content: center;
  grid-gap: ${pxToRem(4)};

  @media (min-width: ${variables.tabletMinWidth}) {
    grid-template-areas: "copyright links";
  }
`;

const footerLinkList = css`
  grid-area: links;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;

  @media (min-width: ${variables.tabletMinWidth}) {
    justify-content: start;
  }
`;

const footerCopyright = css`
  grid-area: copyright;
`;

const footerListItem = css`
  :not(:last-of-type) :after {
    color: ${variables.colorSecondaryGray};
    content: "\\00B7";
    margin: 0 0.5rem;
  }

  @media (min-width: ${variables.tabletMinWidth}) {
    :after,
    :not(:last-of-type) :after {
      display: none;
    }
    :before {
      color: ${variables.colorSecondaryGray};
      content: "\\00B7";
      margin: 0 0.5rem;
    }
  }
`;

const footerLink = css`
  ${h5Regular};
  color: ${variables.colorSecondaryGray};
`;

const footerCopy = css`
  ${h5Regular};
  color: ${variables.colorSecondaryGray};
`;

const websocketStatus = css`
  position: fixed;
  z-index: 100;
  bottom: ${pxToRem(10)};
  left: ${pxToRem(10)};
  background: ${variables.colorBrandYellow};
  color: ${variables.colorWhite};
  padding: ${pxToRem(2)} ${pxToRem(8)};
  border-radius: ${variables.roundness1};
  ${h6Regular};
`;

type Input = {
  children: ReactNode;
  onScheduleDemo?: () => void;
  showMainNavigation?: boolean;
  workspaceName?: string;
  workspaceLogoUrl?: string;
  navBgColor?: string;
  subscribeChannels?: string[];
  subscribeEvents?: { eventName: string; eventHandler: (data: any) => void }[];
};

const MarketingLayout = ({
  children,
  onScheduleDemo,
  showMainNavigation = true,
  workspaceName = "Invent Value",
  workspaceLogoUrl,
  navBgColor,
  subscribeChannels = [],
  subscribeEvents = [],
}: Input): ReactElement => {
  const hasJwtToken = !!getLocalStorage("jwtToken") || false;

  const [isWebsocketConnected, setIsWebsocketConnected] = useState<boolean>();

  const initWebsocket = (fws, websocketHost) => {
    const config = {
      websocketHost,
    };

    fws.init(config, () => {
      fws.send(WSEvent.AUTHENTICATE, { guest: true });
    });

    fws.on(WSEvent.AUTH_SUCCESS, () => {
      fws.processQueue();
    });

    fws.on(WSEvent.AUTH_FAILED, () => {
      console.error("[websocket] Auth failed");
    });

    fws.onConnected = () => {
      setIsWebsocketConnected(true);
    };

    fws.onDisconnected = () => {
      setIsWebsocketConnected(false);
    };
  };

  const subscribe = ({ subscribeChannels, subscribeEvents }) => {
    subscribeChannels.forEach((channel: string) => fws.subscribe(channel));
    subscribeEvents.forEach(({ eventName, eventHandler }) =>
      fws.on(eventName, eventHandler)
    );
  };

  const unsubscribe = ({ subscribeChannels, subscribeEvents }) => {
    subscribeEvents.forEach((eventName) => fws.off(eventName));
    subscribeChannels.forEach((channel: string) => fws.unsubscribe(channel));
  };

  useEffect(() => {
    if (subscribeChannels.length > 0) {
      initWebsocket(fws, websocketHost);
      subscribe({ subscribeChannels, subscribeEvents });
    }

    return () => {
      if (subscribeChannels.length > 0) {
        unsubscribe({ subscribeChannels, subscribeEvents });
      }
    };
  }, []);

  return (
    <>
      <Global styles={marketingStyles} />
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <div css={[mainFooterWrapper, showMainNavigation && navAndFooterWrapper]}>
        {showMainNavigation && (
          <div css={navSection} style={{ backgroundColor: navBgColor }}>
            <MarketingMobileNav
              onScheduleDemo={onScheduleDemo}
              hasJwtToken={hasJwtToken}
              workspaceName={workspaceName}
              workspaceLogoUrl={workspaceLogoUrl ?? DEFAULT_LOGO_URL}
            />
            <div css={desktopNav}>
              <ActiveLink href="/">
                <img
                  css={logoFull}
                  src={workspaceLogoUrl ?? DEFAULT_LOGO_URL}
                  alt={workspaceName}
                />
              </ActiveLink>
              <nav>
                <ul css={menuList}>
                  <li>
                    <ActiveLink style={link} href="/resources">
                      Resources
                    </ActiveLink>
                  </li>
                  {!hasJwtToken && (
                    <li>
                      <ActiveLink style={featuredLink} href="/sign-in">
                        Sign In
                      </ActiveLink>
                    </li>
                  )}
                  {hasJwtToken && (
                    <li>
                      <ActiveLink style={featuredLink} href="/challenges">
                        Continue
                      </ActiveLink>
                    </li>
                  )}
                  <li>
                    <HeroButton
                      style={scheduleDemoButton}
                      onClick={onScheduleDemo}
                    >
                      Schedule Demo
                    </HeroButton>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}

        <main css={content}>{children}</main>

        {showMainNavigation && (
          <footer css={footer}>
            <div css={poweredBy}>
              <p css={poweredByLabel}>Powered by</p>
              <ExternalLink
                href="https://www.freshconsulting.com"
                title="Fresh Consulting"
              >
                <img
                  css={freshLogo}
                  src="/static/fresh-logo-green.png"
                  alt="Fresh Consulting Logo"
                />
              </ExternalLink>
            </div>
            <div css={footerCopyrightLink}>
              <ul css={footerLinkList}>
                <li css={footerListItem}>
                  <ActiveLink style={footerLink} href="/resources">
                    Resources
                  </ActiveLink>
                </li>
                <li css={footerListItem}>
                  <ActiveLink style={footerLink} href="/changelog">
                    Changelog
                  </ActiveLink>
                </li>
                <li css={footerListItem}>
                  <ActiveLink style={footerLink} href="/privacy-policy">
                    Privacy
                  </ActiveLink>
                </li>
                <li css={footerListItem}>
                  <ActiveLink style={footerLink} href="/terms">
                    Terms
                  </ActiveLink>
                </li>
              </ul>
              <ul css={footerCopyright}>
                <li>
                  <p css={footerCopy}>
                    &copy; <DisplayTime date={new Date()} format="yyyy" /> Fresh
                    Consulting
                  </p>
                </li>
              </ul>
            </div>
          </footer>
        )}
      </div>
      {isWebsocketConnected === false && (
        <div css={websocketStatus}>{i18n.t("reconnecting")}</div>
      )}
    </>
  );
};

export default MarketingLayout;
