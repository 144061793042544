import React, { ReactElement } from "react";
import { DateTime } from "luxon";
import Tooltip from "./tooltip";
import DisplayRelativeTime from "./display-relative-time";

const getDateTime = (dateString, date): DateTime => {
  if (dateString) {
    return DateTime.fromISO(dateString);
  } else if (date) {
    return DateTime.fromJSDate(date);
  }
};

type Input = {
  date?: Date;
  dateString?: string;
  format?: string;
  showRelativeTimeOnHover?: boolean;
};

const DisplayTime = ({
  date,
  dateString,
  format = "ccc, MMM d, yyyy",
  showRelativeTimeOnHover = false,
}: Input): ReactElement => {
  const dateTime = getDateTime(dateString, date);

  let time;
  if (typeof format === "string") {
    time = dateTime?.toFormat(format);
  } else {
    time = dateTime?.toLocaleString(format);
  }

  if (showRelativeTimeOnHover) {
    return (
      <Tooltip
        placement="bottom"
        overlay={
          <DisplayRelativeTime
            dateString={dateString}
            date={date}
            autoUpdateInSeconds={60}
          />
        }
      >
        <span>{time}</span>
      </Tooltip>
    );
  }

  return <>{time}</>;
};

export default DisplayTime;
