import React, { ReactElement, ReactNode } from "react";
import { SerializedStyles } from "@emotion/utils/types";

type Props = {
  children: ReactNode;
  style?: SerializedStyles;
  title?: string;
  href: string;
  isStopPropagation?: boolean;
};

const ExternalLink = ({
  href,
  style,
  title,
  children,
  isStopPropagation,
}: Props): ReactElement => {
  return (
    <a
      css={style}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      title={title}
      onClick={(e) => isStopPropagation && e.stopPropagation()}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
